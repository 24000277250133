<template>
  <div>
    <layout-full>
      <router-view />
    </layout-full>
      <wameed-loading-overlay  :isLoading="getloading"/>
  </div>
</template>

<script>
import LayoutFull from "@core/layouts/layout-full/LayoutFull.vue";
import {WameedLoadingOverlay}  from 'wameed-ui/dist/wameed-ui.esm'; 

import { mapGetters } from 'vuex'
export default {
  components: {
    LayoutFull,
    WameedLoadingOverlay,
  },
  computed: {
    ...mapGetters({ getloading: 'getloading' }),
  },
};
</script>
